import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import "./index.scss";
import WidgetContainer from "../../WidgetContainer/WidgetContainer";
import { sendClickEvent } from "../../../helpers/garfield";
import { TECH_BLOG_CATEGORY_NAV_BAR } from "../../../data/techBlogEventConstants";

const BlogsSubNavBar = ({ categoryList, location }) => {
  const timerRef = useRef(null);
  const anchorParentRef = useRef(null);

  const [leftPos, setLeftPos] = useState(0);

  useLayoutEffect(() => {
    if (anchorParentRef.current) {
      const locationStatePos = location?.state?.leftPos;
      anchorParentRef.current.scrollLeft = locationStatePos
        ? locationStatePos
        : 0;
    }
  }, []);

  return (
    <WidgetContainer stylesClass="blogs-sub-nav-widget-container">
      <div className="blogs-sub-nav-outer-container">
        <div
          className="blogs-sub-nav-container hide-scroller"
          ref={anchorParentRef}
          onScroll={e => {
            const leftPosn = e.target.scrollLeft;
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
              setLeftPos(leftPosn);
            }, 50);
          }}
        >
          <div className="blogs-sub-nav">
            <Link
              to={"/"}
              className="blogs-sub-nav-item"
              activeClassName="blogs-sub-nav-active"
              state={{ leftPos: leftPos }}
              onClick={() =>
                sendClickEvent(
                  TECH_BLOG_CATEGORY_NAV_BAR,
                  "all-tech-blogs"
                )
              }
            >
              All
            </Link>
            {categoryList.map(item => {
              const { id: key, name, slug } = item;

              return (
                <Link
                  to={`/category/${slug}/`}
                  className="blogs-sub-nav-item"
                  activeClassName="blogs-sub-nav-active"
                  key={key}
                  state={{ leftPos: leftPos }}
                  onClick={() =>
                    sendClickEvent(
                      TECH_BLOG_CATEGORY_NAV_BAR,
                      `/category/${slug}/`
                    )
                  }
                >
                  {name}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </WidgetContainer>
  );
};

BlogsSubNavBar.propTypes = {
  categoryList: PropTypes.array.isRequired,
  location: PropTypes.object,
};

export default BlogsSubNavBar;
