import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

import "./BlogsMainHeroBanner.scss";

const BlogsMainHeroBanner = () => {
  const data = useStaticQuery(graphql`
    query BlogsMainHeroBannerQuery {
      desktopImage: file(
        relativePath: {
          eq: "blogs/blogs-main/tech-blog-hero-banner-desktop.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      mobileImage: file(
        relativePath: {
          eq: "blogs/blogs-main/tech-blog-hero-banner-mob.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const image = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 600px)`,
    },
  ];

  return (
    <div className="blogs-main-hero-container">
      <div className="blogs-main-hero-image-container">
        <Image fluid={image} />
      </div>
      <div className="blogs-main-heading-container">
        <h1>
          PhonePe <span>Tech Blog</span>
        </h1>
      </div>
    </div>
  );
};

export default BlogsMainHeroBanner;
