import React from "react";
import { graphql } from "gatsby";
import BlogCard from "../../components/BlogCard/index.js";
import BlogsMainHeroBanner from "../../components/BlogsMainHeroBanner/BlogsMainHeroBanner.js";
import WidgetContainer from "../../components/WidgetContainer/WidgetContainer.js";
import BlogGrid from "../../components/BlogGrid/index.js";
import Layout from "../../components/Layout/Layout.js";
import Seo from "../../components/seo.js";
import BlogsSubNavBar from "../../components/CategoryPage/BlogsSubNavBar/index.js";
import { flattenBlogData } from "../../utils/index.js";

const Home = ({ data, pageContext, location }) => {
  const posts = data.allWpPost.nodes;
  const seoTitle = data.wpCategory?.name || "Home";
  const flattenBlogs = flattenBlogData(posts);

  return (
    <Layout isHomePage>
      <Seo title={`${seoTitle} | All posts`} />
      <BlogsMainHeroBanner />
      <WidgetContainer>
        <BlogsSubNavBar
          categoryList={data.categoriesData.nodes}
          location={location}
        />
        <BlogGrid>
          {flattenBlogs.map(blogPost => (
            <BlogCard
              key={blogPost.uri}
              postData={blogPost}
              defaultImage={data.defaultImage}
            />
          ))}
        </BlogGrid>
      </WidgetContainer>
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query WordPressPostArchive {
    defaultImage: file(
      relativePath: { eq: "blogs-default-image.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            slug
            id
            name
          }
        }
        author {
          node {
            id
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        subtitleAcf {
          text
        }
      }
    }
    categoriesData: allWpCategory(
      filter: { count: { gt: 0 } }
    ) {
      nodes {
        id
        slug
        name
        count
        uri
      }
    }
  }
`;
